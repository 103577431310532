import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'

import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from './store'

import ProtectedRoute from './ProtectedRoute'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const PasswordLogin = React.lazy(() => import('./views/pages/login/PasswordLogin'))
const Signup = React.lazy(() => import('./views/pages/login/Signup'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  // state = this.getCurrentStateFromStore()
  //
  // getCurrentStateFromStore() {
  //   return {
  //     auth: store.getState().auth,
  //   }
  // }

  render() {
    return (
      <PersistGate persistor={persistor} loading={null}>
        <Router>
          <Suspense fallback={<CSpinner color="primary" />}>
            <Switch>
              <Route exact path="/">
                <HashRouter>
                  <Switch>
                    <ProtectedRoute
                      exact
                      path="/login"
                      // component={Login}
                      render={(props) => <Login {...props} />}
                    />
                    <ProtectedRoute
                      exact
                      path="/pw-login"
                      // component={Login}
                      render={(props) => <PasswordLogin {...props} />}
                    />
                    <ProtectedRoute
                      exact
                      path="/signup"
                      // component={Login}
                      render={(props) => <Signup {...props} />}
                    />
                    <Route
                      exact
                      path="/404"
                      name="Page 404"
                      render={(props) => <Page404 {...props} />}
                    />
                    <Route
                      exact
                      path="/500"
                      name="Page 500"
                      render={(props) => <Page500 {...props} />}
                    />
                    {/*<Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />*/}
                    <Route path="*" name="Home" render={(props) => <DefaultLayout {...props} />} />
                  </Switch>
                </HashRouter>
              </Route>
              <Redirect from="*" to="/" />
              {/*<Route path="*" render={(props) => <Page404 {...props} />} />*/}
            </Switch>
          </Suspense>
        </Router>
      </PersistGate>
    )
  }
}

export default App
