import { createSlice } from '@reduxjs/toolkit'
import { ppApi } from '../../services/ppService.js'

import { normalize, schema } from 'normalizr'

//type State = {
//  token: string | null;
//  refreshToken: string | null;
//  account: AccountResponse | null;
//};

//const initialState: State = { token: null, refreshToken: null, account: null };

//const problemSeverity = new schema.Entity('severities')
// const supportRequest = new schema.Entity('requests')
// const supportSolution = new schema.Entity('solutions', {
//   support_requests: [supportRequest],
// })

// const extractSeverities = (requests) => {
//   const severities = {}
//   var req = {}
//   for (var i = 0; i < requests.length; i++) {
//     req = requests[i]
//     if (!Object.keys(severities).includes(req.severity)) {
//       severities[req.severity] = {
//         severityDesc: req.get_severity_display,
//         responseTime: req.response_time,
//       }
//     }
//   }
//
//   return severities
// }

const salesSlice = createSlice({
  name: 'sales',
  initialState: {
    referralsPageSize: 10,
    showWithStatus: 3,
    loading: true,
  },
  reducers: {
    setReferralsPage(state, action) {
      state.referralsPageSize = action.payload
    },
    setReferralsFilter(state, action) {
      state.showWithStatus = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    // setSolutions(state, action) {
    //   state.solutions = action.payload
    // },
  },
  // extraReducers: (builder) => {
  //   builder.addMatcher(ppApi.endpoints.getSolutions.matchFulfilled, (state, { payload }) => {
  //     const normalized = normalize(payload, [supportSolution])
  //     // console.log('!!!!getSolutions.matchFulfilled matcher triggered -> ', normalized.entities)
  //     state.solutions = normalized.entities
  //     state.severities = extractSeverities(Object.values(normalized.entities.requests))
  //   })
  // },
})

export default salesSlice
